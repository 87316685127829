export default [ 
    {
        id:6,
        author:'Nana Addo Dankwa Akufo-Addo, President of the Republic of Ghana',
        title:'Trade and Continental Integration',
        image:require('../assets/challengeAuthors/nana.jpeg'),
    },
    {
        id:1,
        author:'Thabo Mbeki, second president of South Africa',
        title:'Good Governance, Inclusion and Equity',
        image:require('../assets/challengeAuthors/mebeki.jpeg'),
    },
    {
        id:7,
        author:'Catherine Samba-Panza, former interim president of the Central African Republic',
        title:'Agriculture and Food Security',
        image:require('../assets/challengeAuthors/catetrine.jpg'),
    },
    {
        id:8,
        author:'Ellen Johnson Sirleaf, former president of Liberia',
        title:'Education and skills',
        image:require('../assets/challengeAuthors/ellen.jpeg'),
    },
    {
        id:9,
        author:'Macky Sall, President of Senegal',
        title:'Health and Wellbeing',
        image:require('../assets/challengeAuthors/makysal.jpeg'),
    },
    {
        id:10,
        author:'Bibi Ameenah Firdaus Gurib-Fakim, former president of Mauritius',
        title:'Natural Capital and Environment',
        image:require('../assets/challengeAuthors/gurib.png'),
    },
    {
        id:2,
        author:'Hailemariam Desalegn, former Prime Minister of Ethiopia',
        title:'Industrialisation, Infrastructure and Energy systems',
        image:require('../assets/challengeAuthors/desalegn.jpg'),
    },
    {
        id:11,
        author:'Mahamudu Bawumia, Vice-President of Ghana',
        title:'Technology',
        image:require('../assets/challengeAuthors/Dr_Bawumia.jpg'),
    },
  
]
