<template>
    <div>
        <section class="container-fluid">
          <div class="row pb-4 my-5" >
               <div class="col-md-7">
               <div class="card bg-image ">
                       <h3 class="text-white text-center pt-5" style="line-height:1.8em">
                       Africa's foremost leaders are challenging you to submit your bold ideas to the <br> 
                       <a class="text-challenge" href="/challenge"><strong>AfCFTA vision's contest</strong></a>
                   </h3>
                   
               </div>
           </div>
           <aside class="col-md-5 text-center" >
               <img class="img-fluid img-thumbnail" src="../assets/AFCFTA IMG1.jpg" alt="">
               <p class="welcome text-white text-center"><a href="#articles" class="welcomelink">Welcome to AfCFTA Visions by H.E Wamkele Mene</a> </p>
           </aside>
          </div>
         
        </section>
        <section class="blog-posts bg-danger py-4">
            <div class="container page-content" >
                
                <div class="row justify-content-between">
                     <!-- trending debates -->
                     <div class="col-md-8" style="margin:40px 0" v-if="challenges">
                        <div class="row">
                            <div class="col-12">
                                 <div class="card">
                                    <div class="card-body">
                                        <h3 style="border-bottom:2px solid red; font-style:italic; text-aling:center">
                                            TRENDING CHALLENGES
                                        </h3>
                                    </div>
                                    <!-- BODY -->
                                    <div class="row justify-content-center">
                                        <div class="col-md-10 py-3 my-2"
                                        v-for="(challenge,key) in challenges" :key="key">
                                        <div class="row justify-content-center" v-if="key < 4">
                                            <div class="col-md-6 text-center">
                                                <p>
                                                    <router-link class="issue_area" :to="`/challenge/${challenge.id}/leaders_vision`">
                                                        <strong>Issue Area</strong>:{{challenge.issue_area && challenge.issue_area[0]}}
                                                    </router-link>
                                                </p>
                                                <img class="img-thumbnail"  :src="`${challenge.image}`">
                                                <p class="text">{{challenge.author}}
                                                    <br><router-link style="color:yellow" :to="`/challenge/${challenge.id}/leaders_vision`">See More..</router-link>
                                                </p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 trending-debates">
                                <div class="card">
                                    <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <h2 class="px-5 py-4">
                                            TRENDING DEBATES
                                        </h2>
                                        <hr>
                                    </div>
                                </div>
                                    <div class="row justify-content-center">
                                        <div class="col-md-10">
                                            <article class=" mb-3" id="trending"> 
                                                <div class="row" v-for="(debate, key) in debates" :key="key">
                                                    <div class="col-sm-2 hidden-sm-down text-center">
                                                        <h1 class="mt-5 text-danger">{{key + 1 }}</h1>
                                                    </div>
                                                    <div class="col-sm-10 p-3">
                                                        <h3 class="text-danger text-center p-2">
                                                             <router-link class="text-danger" style="" :to="`/debatedetails/${debate.id}`"> {{debate.topic}}</router-link>
                                                            </h3>
                                                        <img class="img-thumbnail" :src="debate.image" alt="">
                                                        <p class="p-3">{{debate.body.slice(0,200)}} 
                                                            <router-link style="" :to="`/debatedetails/${debate.id}`">See More..</router-link>
                                                    <p class="p-3"><a class="debate" :href="`/debate/${debate.id}/add_lead_opinion`">Contribute</a></p>
                                                    <hr>
                                                    </div>
                                                    
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                     <div class="col-md-4" style="margin-top:40px">
                         <div class="row">
                             <div class="col-12">
                                 
                                <div class="card">
                                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/6K5HY46yoC4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                
                             </div>
                              <div class="col-12">
                                  <div class="card article-card">
                                    <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <h2 class="px-3 py-4 head" 
                                          style="font-style:italic; border-bottom:2px solid red;">
                                        ARTICLES/ESSAYS
                                        </h2>
                                    </div>
                                </div>
                                <div class="row px-3 py-3" id="articles">
                                            <div class="col-12 my-2 p-2 " v-for="(article,key) in articles" :key="key">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <p><strong class="pr-2 sm-strong">Topic: </strong> {{article.topic}}</p>
                                                    </div>
                                                    <div class="card-body">
                                                        <img class="img-thumbnail" :src="article.image" alt="">
                                                    </div>
                                                    <div class="card-footer">
                                                        <p>{{article.author}} <router-link :to="`/article/${article.id}`">Read more..</router-link> </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                              </div>
                         </div>
                     </div>

                </div>
            </div>
        </section>
       
    </div>
</template>

<script>
import authors from '../helpers/authors'
import debateAuthor from '../helpers/debates'
import challengeAuthors from '../helpers/challengeAuthors'
import toastMixin from '../mixins/toasterMinxin'

    export default {
        data() {
            return { 
                articles:[],
                debates:[],
                challenges:[],
            }
        },
    methods: {
         
         fetchApprovedArticle() {
                this.$store.dispatch('fetchApprovedArticle')
                    .then( response => {
                        const data = response.data
                    
                        const result = authors.map(item => {
                            const obj = data.find(o => o.id === item.id);
                            return { ...item, ...obj };
                        });
                          this.articles = result
                    })
                    .catch(err => {
                        console.log(err)
                        if(err?.response?.data == undefined) {
                        this.toast({
                            message:'Network error. Check network connection and try again.',
                            type:'error'
                        })
                    }
                    })
            },
             fetchApprovedDebateArticle() {
                this.$store.dispatch('fetchApprovedArticle')
                    .then( response => {
                        const data = response.data
                    
                        const result = debateAuthor.map(item => {
                            const obj = data.find(o => o.id === item.id);
                            return { ...item, ...obj };
                        });
                          this.debates = result
                    })
                    .catch(err => {
                        
                        if(err?.response?.data == undefined) {
                        this.toast({
                            message:'Network error. Check network connection and try again.',
                            type:'error'
                        })
                    }
                    })
            }
    },
    computed: {
        leadersVisions() {
            return this.$store.getters.leadersVisions
        }
    },
    created() {
        this.$store.dispatch('fetchLeadersVision').then( response => {
                        const data = response.data
                        
                        const result = challengeAuthors.map(item => {
                            const obj = data.find(o => o.id === item.id);
                            return { ...item, ...obj };
                        });
                          this.challenges = result
                    })
        this.fetchApprovedArticle()
        this.fetchApprovedDebateArticle()
    },
    }
</script>

<style  scoped>
.bg-image{
    background: url('../assets/AFCFTAIMG.jpg') center center;
    height: 350px;
    background-size:cover;
    padding-top: 50px;
}
.bg-image2{
    background: url('../assets/AFCFTA.jpg') center center;
    height: 300px;
    background-size:cover;
    background-position: center center;
}
.bg-imge{
    background: url('../assets/AFCFTA IMG1.jpg') center center;
    height: 350px;
    background-size:cover;
}
.welcome{
    background: #ba2428;
    padding:1.5em 0;
}
.bg-img{
    background: #ba2428;
    height: 300px;
}
hr{
    background:#ba2428;
    height: 2px;
}
strong{
    font-size: 1.5em;
}
.welcomelink{
    color:#fff;
}
.text-challenge{
    color:#fff;
    text-decoration: none;
    transition: all 0.5s;
}
.text-challenge:hover{
    text-decoration: underline;
    font-weight: bolder;
}
.text{
    color:#fff;
     background: #ba2428;
     padding:5px 10px;
}
.text a{
 text-decoration:none;
 color:rgb(61, 60, 60);
 font-size: bolder;
 translate: all 0.5s;
 border-bottom: underline;
}
.text a:hover {
    color: #fff
}
.card-footer{
   color:#fff;
     background: #ba2428; 
}
.card-footer a {
    color:yellow;
    font-size: bolder;
}
.debate {
    background-color: #ba2428;
    padding:1em;
    color:#fff;
}
.article-card{
    margin-top:3em;
}
.trending-debates{
    margin-top:50px;
}
.issue_area{
    text-decoration: none;
    color:#000;
    transition: all .5s;
}
a:hover{
    text-decoration: underline;
}
@media screen and (max-width: 600px) {
    .bg-image{
        height:400px;
        padding:10px;
    }
    .head{
        font-size: 1.7em;
    }
    .sm-strong{
        font-size: 1.1em;
    }
    .side-content-2{
        margin-top:40px;
    }
}
</style>