export default [ 
        {
            id:4,
            author:'Akinbobola Olukayode “Kay” Olugbemi ',
            date:'TUESDAY MAY 19, 2020',
            image:'https://cdn.tradefinanceglobal.com/wp-content/uploads/2020/05/Akinbobola-Olukayode-Olugbemi.jpg',
            source:'https://www.tradefinanceglobal.com/posts/rejigging-africa-trade-position-post-covid19-the-afcfta-option/',
            sourcename:'Trade Finance Global Articles'
        },
        {   id:5,
            author:'Olusegun Obasanjo.',
            date:'28/08/2020',
            image:'https://newafricanmagazine.com/wordpress/wp-content/uploads/2020/08/Olusegun-Obasanjo.png',
            source:'https://newafricanmagazine.com/24231/',
            sourcename:'New Africa Magazine'
        },
        {   id:6,
            author:'Jaime de Melo',
            date:'',
            image:'https://blogsmedia.lse.ac.uk/blogs.dir/99/files/2019/04/Port-of-Mombasa-Kenya.jpg',
            sourcename:'LSE Business Review',
            source:'https://blogs.lse.ac.uk/businessreview/2019/04/05/africas-continental-free-trade-area-progress-and-challenges/'
        },
         {   id:7,
             author:'Anabel Gonzalez ',
             date:'August 30, 2018',
             sourcename:'policycenter.ma',
            image:'../assets/article/Port-of-Mombasa-Kenya.jpg',
            source:'https://www.policycenter.ma/blog/what-will-it-take-realize-promise-african-continental-free-trade-area#.X7Z3F3X7TeR'
         },
        {
            id:1,
            author:'Kingsley Ighobor',
            date:'15 May 2020',
            image:'https://www.un.org/africarenewal/sites/www.un.org.africarenewal/files/styles/ar_main_story_big_picture/public/1SG_AfCFTA_PROFILEPIC.jpg?itok=Zu3EmN5A',
            sourcename:'ABOUT AFRICA RENEWAL',
            source:'https://www.un.org/africarenewal/magazine/may-2020/coronavirus/implementing-africa%E2%80%99s-free-trade-pact-best-stimulus-post-covid-19-economies'
        },
        {
            id:2,
            author:' Mr. George Boateng and Ms. Beatrice Oforiwaa Dankyi',
            date:' January 13, 2020',
            image:'https://acetforafrica.org/acet/wp-content/uploads/2020/01/Trade-Africa.png',
            sourcename:'ACET African Center for Economic Transformation',
            source:'https://acetforafrica.org/media/opinion/the-afcfta-may-be-the-last-opportunity-for-africas-economic-transformation/'
        }
    ]
