/* allow-no-unused-vars */
import axios from 'axios' 
import url from '@/helpers/url'
import VueSessionStorage from 'vue-sessionstorage'
import Vue from 'vue'

export const state = {
    leadersVisions:[]
}
export const actions = {
    fetchLeadersVision({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.get(`${url()}/api/blog/approved-leaders-visions`,{
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            })
                .then(response => {
                    commit('ADD_LEADERS_VISIONS',response.data)
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    addLeadersVision({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.post(`${url()}/api/blog/leaders-vision`,data,{
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            })
                .then(response => {
                    commit('ADD_LEADERS_VISIONS',response.data)
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    addThinkersCretique({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.post(`${url()}/api/blog/thinkers-critique`, data,{
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            })
                .then(response => {
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
    addEnterpreneursResponse({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.post(`${url()}/api/blog/enterprenuers-plan`, data, {
                headers: {
                    'Authorization':`${Vue.prototype.$session.get('entity')}`
                }
            }).then(response => {
                    resolve(response)
                }).catch(err => reject(err))
        })
    },
   
}

export const mutations = {
    ADD_LEADERS_VISIONS(state, data) {
        state.leadersVisions = data
    }
}

export const getters = {
    leadersVisions : state => state.leadersVisions
}