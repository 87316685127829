<template>
  <div>
    <Appbar/>
    <Hero/>
    <MainBlog/>
    <Footer/>
    <FinalFooter />
  </div>
</template>

<script>
import Appbar from '@/components/appBar.vue'
import Hero from '@/components/hero'
import MainBlog from '@/components/mainBlog'
import Footer from '@/components/footer'
import FinalFooter from '../components/FinalFooter'
import Partners from '@/components/partners'
import FinalFooterVue from '../components/FinalFooter.vue'

  export default {
    components:{
      Appbar,
      Hero,
      MainBlog,
      Partners,
      Footer,
      FinalFooter
    }
  }
</script>
