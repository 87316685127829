export default [ 
    {
        id:5,
        author:'Prince C. Oguguo',
        date:'February 8, 2019 12.08am SAST',
        image:'https://images.theconversation.com/files/247804/original/file-20181128-32236-rkg90f.jpg?ixlib=rb-1.1.0&rect=0%2C79%2C1200%2C804&q=45&auto=format&w=926&fit=clip',
        source:'https://theconversation.com/debate-nigeria-isnt-buying-into-africas-free-trade-area-but-should-106108',
        sourcename:'The Conversations'
    },
    {   id:6,
        author:' ARCHIE MATHESON',
        date:'JUNE 26, 2019',
        image:'https://africanarguments.org/wp-content/uploads/2019/06/5895405116_26b8cf7304_b.jpg',
        source:'https://africanarguments.org/2019/06/26/the-afcfta-is-laudable-but-its-imminent-benefits-are-overstated/',
        sourcename:'New Africa Magazine'
    }
]
