<template>
    <div class="jumbotron jumbotron-fluid">
  <div class="container">
    <div class="row text-center jumbotext">
       <div class="col-12">
            <h1 class="text-white display-4">Debating & Shaping AfCFTA </h1>
       </div>
    </div>
  </div>
</div>
</template>

<script>
    export default {
        
    }
</script>

<style  scoped>
    .jumbotron{
    background:url('../assets/AFCFTA BLOG lp-min.jpg');
    background-size:cover;
    height:740px;
    margin-top:-75px
    }
    .jumbotext{
        margin-top:330px;
    }
   

</style>