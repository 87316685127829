let service = () =>{
    let service
    if(process.env.NODE_ENV === 'development') {
        service = `service=http://localhost:8083`
    } else {
        // stagging service 
         //service = `service=http://test.afcfta.blog/` 
        // live
         service = `service=https://afcfta.blog/`
    }
    return service
}

export default service;